import SocialServices from '@sqs/enums/SocialServices';
import RecordType from '@sqs/enums/RecordType';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/social-buttons.css';
/**
 * Social Button Widget
 *
 * @module squarespace-social-button
 */
YUI.add('squarespace-social-buttons', function(Y) {
  Y.namespace('Squarespace');

  var Static = Y.config.win.Static;

  var SocialButton = Y.Squarespace.SocialButton;

  /**
   * Renders buttons across the page that expand
   * to a dropdown list of social services' share buttons.
   *
   * Automatically initialized if `.squarespace-social-button` exist.
   *
   * @class       SocialButtons
   * @namespace   Squarespace
   * @constructor
   */
  var SocialButtons =
  Y.Squarespace.SocialButtons = Y.Base.create('socialButtons', Y.Base, [], {
    initializer: function(config) {
      var services = this.get('services');
      /*
       * Array of configuration objects for individual buttons of the form:
       *  {
       *    title: (Title of the page to share),
       *    url: (URL of the page to share),
       *    id: (Unique identifier for the button),
       *    node: (Container node)
       *  }
       */
      this._buttonConfigs = {};

      /*
       * Array of SocialButton widgets.
       */
      this._buttons = [];

      /*
       * Array of event handles.
       */
      this._eventHandles = [];

      if (services.length > 0) {
        this._scanForButtons();
        this._initializeButtons();
        this._bindEvents();
        this._renderButtons();
      } else {
        this._markButtonsAsEmpty();
      }
    },

    _markButtonsAsEmpty: function() {
      Y.all(SocialButtons.SOCIAL_BUTTON_CONTAINER).addClass('empty');
    },

    /**
     * Scans the page for social button containers and
     * creates configuration options for them in
     * _buttonConfigs
     *
     * @method _scanForButtons
     * @protected
     */
    _scanForButtons: function() {
      var buttonContainers = Y.all(SocialButtons.SOCIAL_BUTTON_CONTAINER),
        services = this.get('services');

      buttonContainers.each(function(cont) {
        var title = cont.getAttribute(SocialButtons.TITLE),
          url = Static.SQUARESPACE_CONTEXT.website.baseUrl + cont.getAttribute(SocialButtons.FULL_URL),
          recordType = parseInt(cont.getAttribute(SocialButtons.RECORD_TYPE), 10),
          assetUrl = cont.getAttribute(SocialButtons.ASSET_URL),
          systemDataId = cont.getAttribute(SocialButtons.SYSTEM_DATA_ID),
          id = Y.guid(SocialButtons.ID_PREFIX);

        this._buttonConfigs[id] = {
          id: id,
          title: title,
          url: url,
          recordType: recordType,
          assetUrl: assetUrl,
          systemDataId: systemDataId,
          services: services,
          node: cont
        };

      }, this);
    },

    /**
     * Constructs SocialButton widgets from the
     * button schemas.
     *
     * @method _initializeButtons
     * @protected
     */
    _initializeButtons: function() {
      this._buttons = Y.Array.map(Y.Object.values(this._buttonConfigs), function(config) {
        return new SocialButton(config);
      });
    },

    /**
     * Attach event handlers to button widgets to handle rendering.
     *
     * @method _bindEvents
     * @protected
     */
    _bindEvents: function() {
      this._eventHandles.push(this.after('cleanup', this._defaultDestroy, this));
    },

    /**
     * Render the buttons to the DOM.
     *
     * @method _renderButtons
     * @protected
     */
    _renderButtons: function() {
      this._buttons.filter(this._excludeOnlyPinterest, this).forEach(function(button) {
        var id = button.get('id');
        button.render(this._buttonConfigs[id].node);
      }, this);

    },

    /**
     * Destroy this object.
     *
     * @method destructor
     */
    destructor: function() {
      this.fire('cleanup');
    },

    /**
     * Detach all event handlers created by this object.
     *
     * @method _unbindEvents
     * @private
     */
    _unbindEvents: function() {
      this._eventHandles.forEach(function(handle) {
        handle.detach();
        handle = null;
      });
    },

    /**
     * Default event handler for the destroy event.
     *
     * @method _defaultDestroy
     * @protected
     */
    _defaultDestroy: function() {
      this._destroyButtons();
      this._unbindEvents();
      this.fire('destroyed');
    },

    /**
     * Calls the destructor of all buttons managed by this object.
     *
     * @method _destroyButtons
     * @protected
     */
    _destroyButtons: function() {
      this._buttons.forEach(function(button) {
        button.destroy();
      }, this);
    },

    /**
     * Filter function to exclude social buttons that are for items that are incompatible with Pinterest,
     * but only have pinterest as a valid service.
     *
     * Also excludes buttons for which there is no recordType provided.
     *
     * @method _excludeOnlyPinterest
     * @param btn { Object } SocialButton to check.
     * @return True if the above conditions hold, false otherwise.
     */
    _excludeOnlyPinterest: function(btn) {
      var recordType = btn.get('recordType');
      /*
       * Exclude this button if the template author did not provide a record type or if
       * this button is for an item that is not an image post, but it's only service is
       * Printerest.
       */
      if (recordType !== RecordType.IMAGE && this._onlyServiceIsPinterest(btn)) {
        return false;
      }
      return true;

    },

    _onlyServiceIsPinterest: function(btn) {
      var services = btn.get('services');

      return services.length === 1 && services[0] === SocialServices.PINTEREST;
    }

  }, {

    /**
     * Name of the attribute under which the full URL for a button is stored.
     *
     * @property FULL_URL
     */
    FULL_URL: 'data-full-url',

    /**
     * Name of the attribute under which the asset URL for a button is stored.
     *
     * @property ASSET_URL
     */
    ASSET_URL: 'data-asset-url',

    /**
     * Name of the attribute under which the systemDataId for a button is stored.
     *
     * @property SYSTEM_DATA_ID
     */
    SYSTEM_DATA_ID: 'data-system-data-id',

    /**
     * Name of the attribute under which the record type for a button is stored.
     *
     * @property RECORD_TYPE
     */
    RECORD_TYPE: 'data-record-type',

    /**
     * Prefix for GUIDs generated for social buttons.
     *
     * @property ID_PREFIX
     */
    ID_PREFIX: 'social-',

    /**
     * Name of the attribute under which the title of a link for a button is stored.
     *
     * @property TITLE
     */
    TITLE: 'data-title',

    /**
     * CSS selector used to find social button containers on a page.
     *
     * @property SOCIAL_BUTTON_CONTAINER
     */
    SOCIAL_BUTTON_CONTAINER: '.squarespace-social-buttons',

    ATTRS: {
      /**
       * Social services (by code defined in squarespace-utils) to render.
       *
       * @default Value of Static.SQUARESPACE_CONTEXT.website.shareButtonOptions
       * @attribute services
       * @type Array
       */
      services: {
        valueFn: function() {
          return Y.Array.map(Y.Object.keys(Static.SQUARESPACE_CONTEXT.website.shareButtonOptions || { }),
            function(n) {
              return parseInt(n, 10);
            });
        }

      }
    }
  });

  // ------------------------------------------------------------------------------------------------
  // Initialize
  // ------------------------------------------------------------------------------------------------

  var socialButtonsInstances = [];

  Y.config.win.Squarespace.onInitialize(Y, function() {
    if (!Y.all('.squarespace-social-buttons').isEmpty()) {
      socialButtonsInstances.push(new Y.Squarespace.SocialButtons());
    }
  });

  Y.config.win.Squarespace.onDestroy(Y, function() {
    socialButtonsInstances.forEach(function(instance) {
      instance.destroy();
    });
    socialButtonsInstances.length = 0;
  });

}, '1.0', {
  requires: [
    'array-extras',
    'base',
    'node',
    'squarespace-social-button'
  ]
});

