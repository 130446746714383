import { t } from 'shared/i18n';
import * as resolvedLocales from 'shared/i18n/resolvedLocales';
import SocialServices from '@sqs/enums/SocialServices';
import invert from 'lodash/invert';

/**
 * @module squarespace-social-button
 */
YUI.add('squarespace-social-button', function (Y) {

  Y.namespace('Squarespace');

  var Services = SocialServices;

  /*
   * How long to wait, in ms, after a render
   * script has been loaded to confirm it's actually
   * done its job.
   *
   * This is necessary because not all APIs provide
   * callbacks to confirm that the service has been
   * rendered. It may be better to poll the DOM
   * for their iFrame or other elements,
   * but then we're at the mercy of thir developers.
   *
   */
  var RENDER_SAFETY_DELAY = 400;

  var id = function (obj) {
    return obj;
  };

  /**
   * Renders a button that can expand to a dropdown list of
   * social services' share butons.
   *
   * @class SocialButton
   * @namespace Squarespace
   * @extends Widget
   */
  var SocialButton = Y.Squarespace.SocialButton = Y.Base.create(
  'socialButton',
  Y.Widget,
  [],
  {
    initializer: function (config) {

      /*
       * Map from service constant to Boolean indicating
       * whether the given service has been successfully
       * rendered.
       */
      this._servicesRendered = {};
      config.services.forEach(function (s) {
        this._servicesRendered[s] = false;
      }, this);

      /*
       * The list starts closed.
       */
      this._open = false;

      /*
       * Map of running animations.
       */
      this._anims = {};

      this._serviceRenderers = {};
      this._serviceRenderers[Services.REDDIT] = this._renderReddit;
      this._serviceRenderers[Services.FACEBOOK] = this._renderFacebook;
      this._serviceRenderers[Services.TWITTER] = this._renderTwitter;
      this._serviceRenderers[Services.LINKEDIN] = this._renderLinkedIn;
      this._serviceRenderers[Services.STUMBLE] = this._renderStumble;
      this._serviceRenderers[Services.PINTEREST] = this._renderPinterest;
      this._serviceRenderers[Services.TUMBLR] = this._renderTumblr;

      /**
       * Fired when an individual service is
       * rendered to the page. This includes
       * rendering stubs for batch renderers
       * to pick up.
       * @event serviceRendered
       */
      this.publish('serviceRendered', {
        defaultFn: this._defaultServiceRendered,
        context: this });


      /**
       * Fired when the user clicks on
       * the button.
       * @event buttonClicked
       */
      this.publish('buttonClicked', {
        defaultFn: this._defaultButtonClicked,
        preventable: true,
        context: this });


      /**
       * @event close
       */
      this.publish('close', {
        defaultFn: this.close,
        preventable: true,
        context: this });


      /**
       * Fired when all services on a button have been
       * rendered to the page. (Stubs included)
       * @event servicesRendered
       */
      this.publish('servicesRendered');

      this._serviceContainer = Y.Node.create('<div class="ss-social-button-container"></div>');
    },

    /**
     * Default even handler for the rendering
     * of a single service.
     *
     * @method _defaultServiceRendered
     * @protected
     */
    _defaultServiceRendered: function (e) {
      var service = e.details[0];
      this._servicesRendered[service] = true;
      if (this._allServicesRendered()) {
        this.set('loaded', true);
        this.fire('servicesRendered', this);
      }
    },

    open: function () {
      this._open = true;
      this._openList();
    },

    close: function () {
      this._open = false;
      this._closeList();
    },

    /**
     * DOM click handler to fire our internal click.
     *
     * @method _onClick
     * @protected
     */
    _onClick: function (e) {
      this.fire('buttonClicked', e);
    },

    /**
     * Default buttonClicked handler.
     *
     * @method _defaultButtonClicked
     * @protected
     */
    _defaultButtonClicked: function (e) {
      if (this.get('loaded')) {
        if (this.isOpen()) {
          this.close();
        } else {
          this.open();
        }
      } else if (!this.get('loading')) {
        this.once('servicesRendered', function () {
          this.set('loading', false);
        }, this);

        this.set('loading', true);
        this._renderServices();
        this.open();
      }
    },

    /**
     * Is the button open?
     *
     * @return True if the button is open. False otherwise.
     * @method isOpen
     */
    isOpen: function () {
      return this._open;
    },

    /**
     * Destructor function.
     *
     * @method destructor
     */
    destructor: function () {
      this._stopAnimations();
    },

    /**
     * Stop all animations running on the button.
     *
     * This is used in the destructor in case some
     * component on the back end has destroyed the button
     * prematurely, which happens often...
     *
     * @method _stopAnimations
     * @private
     */
    _stopAnimations: function () {
      Y.Object.values(this._anims).forEach(function (anim) {
        anim.stop();
        anim = null;
      });
    },

    /**
     * Close the button list.
     *
     * @method _closeList
     * @protected
     */
    _closeList: function () {
      var contentEl = this.get('contentBox');

      /*
       * We check for this silly stuff because, in the event
       * that this social button is rendered inside a Settings Panel,
       * the code that handles switching panels simply
       * removes the nodes from the DOM without actually destroying
       * the YUI node references to them. (i.e. calling
       * set('innerHTML', '') instead of empty()
       *
       * Ryan is making it his personal mission to make the panel
       * code actually behave properly.
       */
      if (contentEl._node && contentEl.inDoc()) {
        var listWrapper = contentEl.one('.ss-social-list-wrapper'),
        closeAnim;

        /**
         * We check for this silly stuff because, in the event
         * that this social button is rendered inside a Settings Panel,
         * the code that handles switching panels simply
         * removes the nodes from the DOM without actually destroying
         * the YUI node references to them. (i.e. calling
         * set('innerHTML', '') instead of empty()
         *
         * Ryan is making it his personal mission to make the panel
         * code actually behave properly.
         */
        if (listWrapper && listWrapper._node && listWrapper.inDoc()) {
          closeAnim = new Y.Anim({
            node: listWrapper,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: 0,
              opacity: 0 } });



          this._anims.close = closeAnim;

          closeAnim.on('end', function () {
            // Remove the overflow so that the button list wrapper can actually stay hidden
            // when it's closed and empty.
            listWrapper.setStyle('overflow', null);
            this.fire('listClose');
          }, this);
          closeAnim.run();
        }
      }
    },

    /**
     * Open the button list.
     *
     * @method _openList
     * @protected
     */
    _openList: function () {
      var contentEl = this.get('contentBox');

      /*
       * We check for this silly stuff because, in the event
       * that this social button is rendered inside a Settings Panel,
       * the code that handles switching panels simply
       * removes the nodes from the DOM without actually destroying
       * the YUI node references to them. (i.e. calling
       * set('innerHTML', '') instead of empty()
       *
       * Ryan is making it his personal mission to make the panel
       * code actually behave properly.
       */
      if (contentEl._node && contentEl.inDoc()) {

        var button = contentEl.one('.ss-social-button-wrapper'),
        listWrapper = contentEl.one('.ss-social-list-wrapper'),
        list = contentEl.one('.ss-social-button-list'),
        buttonWidth = button.get('offsetWidth'),
        buttonHeight = button.get('offsetHeight'),
        listWidth = listWrapper.get('offsetWidth'),
        dx = Math.abs(buttonWidth - listWidth),
        clickCapture,
        openAnim;

        // Ensure that the list is centered below
        // the button on the X-Axis by aligning their midpoints
        // and flush with the bottom of the button..
        //
        // If the button is wider than the list,
        // push it to the right. If it's smaller
        // than or equal to the list, push it to the left.
        listWrapper.setStyles({
          'left': (buttonWidth <= listWidth ? -1 : 1) * dx / 2,
          'top': buttonHeight });


        var viewportRegion = Y.DOM.viewportRegion();
        var listRegion = list.get('region');

        var listBottom = listRegion.height + listRegion.top;
        var dy = listBottom - (viewportRegion.height + viewportRegion.top);

        var leftDiff = viewportRegion.left - listRegion.left;
        var leftOverflow = leftDiff > 0;
        var bottomBuffer = 20;
        var leftBuffer = 20;

        // Check if the list is going to render off the bottom of the screen and adjust its position
        // to {bottomBuffer} px above the bottom edge of the window.
        if (dy > 0) {
          listWrapper.setStyle('top', parseInt(listWrapper.getComputedStyle('top'), 10) - dy - bottomBuffer);
        }

        if (leftOverflow) {
          listWrapper.setStyle('left', parseInt(listWrapper.getComputedStyle('left'), 10) + leftDiff + leftBuffer);
        }

        /*
        * We check for this silly stuff because, in the event
        * that this social button is rendered inside a Settings Panel,
        * the code that handles switching panels (panel manager) simply
        * removes the nodes from the DOM without actually destroying
        * the YUI node references to them. (i.e. calling
        * set('innerHTML', '') instead of empty()
        *
        * Ryan is making it his personal mission to make the panel
        * code actually behave properly.
        */
        if (listWrapper && listWrapper._node && listWrapper.inDoc()) {

          openAnim = new Y.Anim({
            node: listWrapper,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: list.get('offsetHeight'),
              opacity: 1 } });



          this._anims.open = openAnim;

          openAnim.on('end', function () {

            // Override the overflow here so that facebook like button comment boxes
            // and any other elements spawned by these widgets that are in the doc flow
            // that overflow the wrapper can be visible.
            listWrapper.setStyle('overflow', 'visible');

            // Get correct document
            var doc = Y.config.win.document;

            // Mobile safari and uiwebview will not respect click
            // events on document unless it has cursor: pointer
            // on it. Yes, this is actually a thing.
            if (Y.UA.touchEnabled) {
              Y.one(doc.documentElement).setStyle('cursor', 'pointer');
            }

            // Add a temporary click handler to capture clicks
            // anywhere outside the button or on a social
            // button besides the one of origin.
            clickCapture = Y.one(doc).on('click', function (e) {

              if (!e.target.ancestor('.ss-social-list-wrapper', true)) {
                this.fire('close');

                clickCapture.detach();
                clickCapture = null;

                // Undo safari hack from above
                Y.one(doc.documentElement).setStyle('cursor', '');
              }
            }, this);

            this.fire('listOpen');
          }, this);
          openAnim.run();
        }
      }
    },


    /**
     * Have all services (or their injection points) been rendered?
     *
     * @method _allServicesRendered
     * @return true if all services have been rendered, false otherwise
     */
    _allServicesRendered: function () {
      return Y.Object.values(this._servicesRendered).every(id);
    },

    /**
     * Bind event handlers to the DOM.
     *
     * Overriding Widget's bindUI.
     * @method bindUI
     */
    bindUI: function () {
      var contentEl = this.get('contentBox'),
      button = contentEl.one('.ss-social-button-wrapper');

      this.after('loadingChange', this._onLoadingChange, this);
      button.on('click', this._onClick, this);
    },

    /**
     * What happens when the 'loading' state changes?
     *
     * @method _onLoadingChange
     * @private
     */
    _onLoadingChange: function () {
      this.get('boundingBox').ancestor('.squarespace-social-buttons').toggleClass('loading', this.get('loading'));
    },

    /**
     * Render content to the DOM.
     *
     * Overriding Widget's renderUI.
     * @method renderUI
     */
    renderUI: function () {
      var contentEl = this.get('contentBox');

      contentEl.append('<div class="ss-social-button-wrapper"><div class="ss-social-button">' +
      '<span class="ss-social-button-icon"></span>' + t("Share") +


      '</div></div>');
      contentEl.append('<div class="ss-social-list-wrapper"><div class="ss-social-button-list"></div></div>');
    },

    /**
     * Renders all service buttons.
     *
     * @method _renderServices
     * @private
     */
    _renderServices: function () {
      var container = this.get('contentBox').one('.ss-social-button-list');
      this.get('services').forEach(function (s) {
        if (s in this._serviceRenderers) {
          this._serviceRenderers[s].call(this, container);
        }
      }, this);
    },


    /**
     * Given a service, generate the default script timeout callback function for it.
     *
     * @method _defaultFailureCb
     * @param service { Number } A social service id.
     * @private
     */
    _defaultTimeoutCb: function (service, containerNode) {
      var serviceNames = invert(SocialServices),
      errorFmt = t("(Social Button) Loading render script for service: {name} too longer than {sub1} seconds. Skipping.",

      {
        sub1: SocialButton.SCRIPT_TIMEOUT / 1000 });






      return function () {
        if (__DEV__) {
          console.warn(Y.substitute(errorFmt, {
            name: serviceNames[service] }));

        }

        this.fire('serviceRendered', service);

        if (containerNode) {
          containerNode.hide();
        }
      };
    },

    /**
     * Given a service, generate the default failure callback function for it.
     *
     * @method _defaultFailureCb
     * @param service { Number } A social service id.
     * @private
     */
    _defaultFailureCb: function (service, containerNode) {
      var serviceNames = invert(SocialServices),
      errorFmt = t("(Social Button) Service {name} render script failed to load.");



      return function () {
        if (__DEV__) {
          console.warn(Y.substitute(errorFmt, {
            name: serviceNames[service] }));

        }

        this.fire('serviceRendered', service);

        if (containerNode) {
          containerNode.hide();
        }
      };
    },

    /**
     * Render a Reddit to a container.
     *
     * @method _renderReddit
     * @param container { Node } Container to render to.
     */
    _renderReddit: function (container) {
      var redditContainer = this._serviceContainer.cloneNode(true),
      url = this.get('url');

      redditContainer.addClass('reddit');
      redditContainer.append(Y.Node.create(SocialButton.REDDIT_LINK));

      redditContainer.on('click', function (e) {
        window.open('http://reddit.com/submit?url=' + encodeURIComponent(url), t("Submit to Reddit"));


        e.stopImmediatePropagation();
      });

      container.append(redditContainer);
      this.fire('serviceRendered', Services.REDDIT);
    },

    _renderTumblr: function (container) {
      var tumblrContainer = this._serviceContainer.cloneNode(true);
      var query = {
        url: this.get('url'),
        name: this.get('title') };

      var queryString = Y.QueryString.stringify(query);

      tumblrContainer.addClass('tumblr');
      tumblrContainer.append(Y.substitute(SocialButton.TUMBLR_TAG_TEMPLATE, {
        query: queryString }));


      container.append(tumblrContainer);

      Y.Get.script(SocialButton.TUMBLR_URL, {
        onSuccess: function () {
          Y.later(RENDER_SAFETY_DELAY, this, function onTumblrLoadedTimeout() {
            this.fire('serviceRendered', Services.TUMBLR);
          });
        },
        onFailure: this._defaultFailureCb(Services.TUMBLR, tumblrContainer),
        onTimeout: this._defaultTimeoutCb(Services.TUMBLR, tumblrContainer),
        timeout: SocialButton.SCRIPT_TIMEOUT,
        context: this,
        win: Y.config.win });


    },

    /**
     * Render a Facebook button to a container.
     *
     * @method _renderFacebook
     * @param {Node} container to render to.
     */
    _renderFacebook: function (container) {
      var facebookContainer = this._serviceContainer.cloneNode(true);
      var url = this.get('url');

      facebookContainer.addClass('facebook');
      facebookContainer.append(Y.Node.create(
      Y.substitute(SocialButton.FACEBOOK_TAG_TEMPLATE, { url: url })));

      container.append(facebookContainer);

      // Facebook wants us to add our appId to the URL.
      var win = Y.config.win;
      var appId = win.Static.SQUARESPACE_CONTEXT.facebookAppId;
      var version = win.Static.SQUARESPACE_CONTEXT.facebookApiVersion;
      var script = Y.substitute(SocialButton.FACEBOOK_URL, {
        locale: resolvedLocales.formatLocaleForFacebook(resolvedLocales.getResolvedWebsiteLocale()) });

      Y.Get.script(script, {
        onSuccess: function () {
          if (win.FB) {
            Y.later(RENDER_SAFETY_DELAY, this, function onFbLoadedTimeout() {
              win.FB.init({
                appId: appId,
                xfbml: false,
                version: version });

              if (win.FB.XFBML && win.FB.XFBML.parse) {
                win.FB.XFBML.parse();
              }
              this.fire('serviceRendered', Services.FACEBOOK);
            });
          }
        },
        onFailure: this._defaultFailureCb(Services.FACEBOOK, facebookContainer),
        onTimeout: this._defaultTimeoutCb(Services.FACEBOOK, facebookContainer),
        timeout: SocialButton.SCRIPT_TIMEOUT,
        context: this });


    },

    /**
     * Render a Twitter button to a container.
     *
     * @method _renderTwitter
     * @param container { Node } Container to render to.
     */
    _renderTwitter: function (container) {
      var twitterContainer = this._serviceContainer.cloneNode(true),
      title = this.get('title'),
      url = this.get('url');

      // Note: Twitter does funky things with the data-text attribute. If you
      // omit it, or provide an empty string, it takes the title of the page
      // on which the button is placed, instead...so to take the path
      // of least resistance, add a single space if the item/collection has
      // no title.
      twitterContainer.append(
      Y.Node.create(
      '<a href="https://twitter.com/share" data-text="' +
      Y.Escape.html(title || '') + '" data-url="' + Y.Escape.html(url) +
      '"class="twitter-share-button">' + t("tweet") +

      '</a>'));


      twitterContainer.addClass('twitter');
      container.append(twitterContainer);

      Y.Get.script(SocialButton.TWITTER_URL, {
        onSuccess: function () {
          Y.later(RENDER_SAFETY_DELAY, this, function onTwitterLoadedTimeout() {
            this.fire('serviceRendered', Services.TWITTER);
          });
        },
        onFailure: this._defaultFailureCb(Services.TWITTER, twitterContainer),
        onTimeout: this._defaultTimeoutCb(Services.TWITTER, twitterContainer),
        timeout: SocialButton.SCRIPT_TIMEOUT,
        context: this });

    },

    /**
     * Render a linkedin button to a container.
     *
     * @method _renderLinkedIn
     * @param container { Node } Container to render to.
     */
    _renderLinkedIn: function (container) {
      var linkedInContainer = this._serviceContainer.cloneNode(true),
      url = this.get('url');

      linkedInContainer.addClass('linkedin');
      linkedInContainer.append(
      Y.Node.create(
      Y.substitute(SocialButton.LINKEDIN_URL_TEMPLATE, { url: url })));


      container.append(linkedInContainer);

      window.IN = undefined;
      Y.Get.script(SocialButton.LINKEDIN_URL, {
        onSuccess: function () {
          Y.later(RENDER_SAFETY_DELAY, this, function onLinkedInLoadedTimeout() {
            this.fire('serviceRendered', Services.LINKEDIN);
          });
        },
        onFailure: this._defaultFailureCb(Services.LINKEDIN, linkedInContainer),
        onTimeout: this._defaultTimeoutCb(Services.LINKEDIN, linkedInContainer),
        timeout: SocialButton.SCRIPT_TIMEOUT,
        context: this });

    },

    /**
     * Render a StumbleUpon button to a container.
     *
     * @method _renderStumble
     * @param container { Node } Container to render to .
     */
    _renderStumble: function (container) {
      var stumbleContainer = this._serviceContainer.cloneNode(true),
      url = this.get('url'),
      thisId = this.get('id');

      stumbleContainer.addClass('stumble');


      stumbleContainer.append(Y.Node.create(
      Y.substitute(SocialButton.STUMBLE_TAG_TEMPLATE, { url: url, id: thisId })));

      container.append(stumbleContainer);

      Y.Get.script(SocialButton.STUMBLE_URL, {
        onSuccess: function () {
          Y.later(RENDER_SAFETY_DELAY, this, function onStumbleUponLoadedTimeout() {
            if (window.STMBLPN) {
              // StumbleUpon's loader must be reset in order for it to render more than once on the same page.
              if (window.STMBLPN.wasProcessLoaded) {
                window.STMBLPN.wasProcessLoaded = false;
              }

              window.STMBLPN.processWidgets();
            }
            this.fire('serviceRendered', SocialServices.STUMBLE);
          });
        },
        onFailure: this._defaultFailureCb(Services.STUMBLE, stumbleContainer),
        onTimeout: this._defaultTimeoutCb(Services.STUMBLE, stumbleContainer),
        timeout: SocialButton.SCRIPT_TIMEOUT,
        context: this });

    },

    /**
     * Render a Pinterest button to a container.
     *
     * @method _renderPinterest
     * @param container { Node } Container to render to .
     */
    _renderPinterest: function (container) {
      var pinterestContainer = this._serviceContainer.cloneNode(true),
      assetUrl = this.get('assetUrl'),
      url = this.get('url'),
      pageUrl = Static.SQUARESPACE_CONTEXT.website.authenticUrl +
      container.ancestor('.squarespace-social-buttons').getAttribute('data-full-url');

      if (this.get('systemDataId')) {
        pinterestContainer.addClass('pinterest');
        pinterestContainer.append(Y.Node.create(
        Y.substitute(SocialButton.PINTEREST_TAG_TEMPLATE, {
          url: encodeURIComponent(assetUrl || url),
          pageUrl: encodeURIComponent(pageUrl) })));


        container.append(pinterestContainer);

        Y.Get.script(SocialButton.PINTEREST_URL, {
          onSuccess: function () {
            Y.later(RENDER_SAFETY_DELAY, this, function onPinterestLoadedTimeout() {
              this.fire('serviceRendered', SocialServices.PINTEREST);
            }, this);
          },
          onFailure: this._defaultFailureCb(Services.PINTEREST, pinterestContainer),
          onTimeout: this._defaultTimeoutCb(Services.PINTEREST, pinterestContainer),
          timeout: SocialButton.SCRIPT_TIMEOUT,
          context: this });

      } else {
        this.fire('serviceRendered', Services.PINTEREST);
      }
    } },

  {

    /**
     * URL of the Twitter render script.
     *
     * @property TWITTER_URL
     */
    TWITTER_URL: '//platform.twitter.com/widgets.js',

    /**
     * URL of the Tumblr render script.
     *
     * @property TUMBLR_URL
     */
    TUMBLR_URL: '//platform.tumblr.com/v1/share.js',

    /**
     * URL of the Facebook XFBML parser script.
     *
     * @property FACEBOOK_URL
     */
    FACEBOOK_URL: '//connect.facebook.net/{locale}/sdk.js',

    /**
     * URL for the LinkedIn render script.
     *
     * @property LINKEDIN_URL
     */
    LINKEDIN_URL: '//platform.linkedin.com/in.js',

    /**
     * URL of the StumbleUpon markup parser script.
     *
     * @property STUMBLE_URL
     */
    STUMBLE_URL: 'http://platform.stumbleupon.com/1/widgets.js',

    /**
     * URL of the Pinterest markup parser script.
     *
     * @property PINTEREST_URL
     */
    PINTEREST_URL: '//assets.pinterest.com/js/pinit.js',

    /**
     * URL (Template) for the LinkedIn render script.
     *
     * @property LINKEDIN_URL_TEMPLATE
     */
    LINKEDIN_URL_TEMPLATE: '<script type="IN/Share" data-url="{url}" data-counter="right"></script>',

    /**
     * Facebook Tag (Template) for the Facebook render script.
     *
     * @property FACEBOOK_TAG_TEMPLATE
     */
    FACEBOOK_TAG_TEMPLATE:
    '<div id="fb-root"></div><fb:like href="{url}" send="false" layout="button_count" show_faces="true"></fb:like>',

    PINTEREST_TAG_TEMPLATE:
    '<a href="//pinterest.com/pin/create/button?url={pageUrl}&media={url}" class="pin-it-button" ' +
    'count-layout="horizontal"><img border="0" src="//assets.pinterest.com/images/PinExt.png" title="' + t("Pin It") +


    '" /></a>',

    TUMBLR_TAG_TEMPLATE:
    '<a href="https://tumblr.com/share/link?{query}" title="' + t("Share on Tumblr") +


    '" style="display:inline-block; text-indent:-9999px; ' +
    'overflow:hidden; width:81px; height:20px; ' +
    'background:url(\'https://platform.tumblr.com/v1/share_1T.png\') top left no-repeat transparent;">' + t("Share on Tumblr") +


    '</a>',

    /**
     * How long, in milliseconds, we will wait for a script to return from Y.Get.script.
     *
     * @property SCRIPT_TIMEOUT
     */
    SCRIPT_TIMEOUT: 5000,

    /**
     * SumbleUpon Tag (Template) for the StumbleUpon render script
     *
     * @property STUMBLE_TAG_TEMPLATE
     */
    STUMBLE_TAG_TEMPLATE: '<su:badge layout="1" location="{url}"></su:badge>',

    /**
     * HTML for the "Post to Reddit" button.
     *
     * @property REDDIT_LINK
     */
    REDDIT_LINK:
    '<a href="#"><img src="https://old.reddit.com/static/spreddit7.gif" alt="' + t("submit to reddit") +


    '" border="0" /></a>',

    ATTRS: {
      /**
       * URL of the page to share.
       *
       * @attribute url
       * @type String
       * @default (Current page location)
       */
      url: {
        value: window.location.href },


      /**
       * Title of the link to share.
       *
       * @attribute title
       * @type String
       * @default (Current page title)
       */
      title: {
        value: document.title || window.location.href },


      /**
       * Social services to render for this button.
       *
       * @attribute services
       * @type Array
       */
      services: {},



      /**
       * The Item type for which this social button is rendered.
       *
       * @attribute recordType
       * @type Number
       */
      recordType: {},



      /**
       * The assetUrl of the item for which this social button is rendered.
       *
       * @attribute assetUrl
       * @type String
       * @default ""
       */
      assetUrl: {
        value: '' },



      /**
       * The systemDataId of the item for which this social button is rendered.
       *
       * @attribute systemDataId
       * @type String
       * @default ""
       */
      systemDataId: {
        value: '' },


      /**
       * Whether the services have been loaded or not.
       *
       * @attribute loaded
       * @type Boolean
       * @default false
       */
      loaded: {
        value: false },


      /**
       * Whether the services are currently loading.
       *
       * @attribute loading
       * @type Boolean
       * @default false
       */
      loading: {
        value: false } } });




}, '1.0', {
  requires: [
  'anim',
  'base',
  'escape',
  'node',
  'querystring-stringify',
  'squarespace-util',
  'substitute',
  'widget'] });